<template>
  <v-card :loading="loading">
    <v-card-title class="headline">
      {{ selectedItem ? "Editar" : "Nuevo" }} punto
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text v-if="!loading">
      <v-snackbar top :timeout="3000" v-model="snackbar">
        {{ snackbarText }}
      </v-snackbar>
      <v-row class="mt-5">
        <v-col cols="12" sm="12" md="12">
          <h2>Nombre del punto</h2>
          <input
            class="control-input"
            placeholder="Nombre"
            v-model="item.name"
          />
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <h2>Nombre del contacto</h2>
          <input
            class="control-input"
            placeholder="Nombre"
            v-model="item.contactName"
          />
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <h2>Teléfono del contacto</h2>
          <input
            class="control-input"
            placeholder="Nombre"
            v-model="item.contactPhone"
          />
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <h2>Tipo de punto</h2>
          <v-select v-model="item.type" outlined :items="types" rounded/>
        </v-col>

        <v-col cols="12" sm="1updated2" md="12">
          <map-picker
            @updated="handleLocationUpdate"
            :latitude="location[0]"
            :longitude="location[1]"
            :name="item.name"
          />
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="6" md="6">
          <v-switch label="Activo" v-model="item.active"></v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-row justify="end">
            <v-btn
              class="save-btn"
              :loading="saving"
              @click="save"
              color="primary"
              dark
              >Guardar</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import mapPicker from "@/components/map-picker";
import { db, fb } from "@/firebase";

export default {
  components: {
    mapPicker,
  },
  name: "edit-starting-point",
  props: ["businessId", "selectedItem"],
  data() {
    return {
      item: {
        name: "",
        active: true,
        deleted: false,
        type : "both"
      },
      types: [
        { text: "Punto Inicial", value: "startingPoint" },
        { text: "Punto Secundario", value: "secondaryPoint" },
        { text: "Punto Inicial y Secundario", value: "both" },
      ],
      loading: true,
      saving: false,
      location: [],
      snackbarText: "",
      snackbar: false,
    };
  },
  methods: {
    handleLocationUpdate(e) {
      this.location = e;
    },
    updateLocation(subcollectionId) {
      return new Promise((resolve, reject) => {
        let data = {
          collection: "businessesCorporative",
          subcollection: "points",
          collectionId: this.businessId,
          subcollectionId,
          latitude: this.location[0],
          longitude: this.location[1],
        };
        var httpGeneralUpdateGeoPoint = fb
          .functions()
          .httpsCallable("httpGeneralUpdateGeoPoint");
        httpGeneralUpdateGeoPoint(data)
          .then((result) => {
            resolve("success");
          })
          .catch((err) => {
            this.saving = false;
            this.snackbar = true;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente";
          });
      });
    },
    save() {
      if (this.item.name && this.location[0] && this.location[1]) {
        this.saving = true;

        let data = {
          name: this.item.name,
          active: this.item.active ? this.item.active : false,
          contactName: this.item.contactName ? this.item.contactName : "",
          contactPhone: this.item.contactPhone ? this.item.contactPhone : "",
          deleted: false,
          type : this.item.type ? this.item.type : "both"
        };

        let query = db
          .collection("businessesCorporative")
          .doc(this.businessId)
          .collection("points");

        if (this.selectedItem) {
          data.modifiedAt = new Date();
          data.modifiedBy = this.$store.state.user[".key"];
          query = query.doc(this.selectedItem[".key"]).update(data);
        } else {
          data.createdAt = new Date();
          data.createdBy = this.$store.state.user[".key"];
          query = query.add(data);
        }

        query
          .then(async (ref) => {
            await this.updateLocation(ref ? ref.id : this.selectedItem[".key"]);

            this.saving = false;

            this.$emit("success");
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      } else {
        this.snackbarText = "Ingrese el nombre y ubicación del punto.";
        this.snackbar = true;
      }
    },
  },
  mounted() {
    if (this.selectedItem) {
      this.item = Object.assign({}, this.selectedItem);

      if (this.item.address && this.item.address.geopoint) {
        this.location = [
          this.item.address.geopoint.latitude,
          this.item.address.geopoint.longitude,
        ];
      } else {
        this.location = [14.092115637451661, -87.19129263336364];
      }
    } else {
      this.location = [14.092115637451661, -87.19129263336364];
    }

    this.loading = false;
  },
};
</script>